<template>
  <div class="flex gap-4 flex-row flex-wrap">
    <vs-card
      @click="$router.push('/game/' + game._id).catch(() => {})"
      type="2"
      :key="game._id"
      v-for="game of games"
      :disabled="game.disabled"
    >
      <template #img>
        <img :src="game.banner" />
      </template>
      <template #text>
        <h2>{{ game.name }}</h2>
      </template>
      <!--        <template #interactions>-->
      <!--          <vs-button danger icon @click="game.favorite = !game.favorite">-->
      <!--            <i class="bx" :class="game.favorite ? 'bxs-heart' : 'bx-heart'"></i>-->
      <!--          </vs-button>-->
      <!--          <vs-button icon>-->
      <!--            <i class="bx bx-star mr-1"></i>-->
      <!--            <span>{{ game.rate }}</span>-->
      <!--          </vs-button>-->
      <!--        </template>-->
    </vs-card>
  </div>
</template>

<script>
import { getAllGames } from "@/services/ApiService";

export default {
  name: "Market",
  data() {
    return {
      games: [],
    };
  },
  methods: {
    getDataFromApi() {
      const loading = this.$vs.loading({
        type: "points",
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
      });
      getAllGames(false)
        .then((data) => {
          this.games = data;
          loading.close();
        })
        .catch((error) => {
          loading.close();
          this.$vs.notification({
            name: "Error",
            text: error.response.data.message,
            color: "danger",
            position: "top-right",
            time: 3000,
          });
        });
    },
  },
  created() {
    this.getDataFromApi();
  },
};
</script>
